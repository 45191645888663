@tailwind base;
@tailwind components;
@tailwind utilities;


:root {
  --stone-color:rgb(168 162 158);
  --stone-color-800: rgb(41 37 36);
}

@font-face {
      src: url("../public/fonts/editor/Editor\'sNote-Medium.ttf");
      font-family: "Editor";
      font-weight: normal;
      font-style: normal;
}

@font-face {
  src: url("../public/fonts/editor/Editor\'sNote-MediumItalic.ttf");
  font-family: "Editor";
  font-weight: normal;
  font-style: italic;
}

@font-face {
  src: url("../public/fonts/editor/Editor\'sNote-Extralight.ttf");
  font-family: "Editor";
  font-weight: lighter;
  font-style: normal;
}

@font-face {
src: url("../public/fonts/editor/Editor\'sNote-ExtralightItalic.ttf");
font-family: "Editor";
font-weight: lighter;
font-style: italic;
}


@font-face {
  src: url("../public/fonts/ppsm/PPSupplyMono-Ultralight.otf");
  font-family: "PPS";
  font-weight: lighter;
  font-style: normal;
  }

@keyframes wave-animation {
 0%  { transform: rotate( 0.0deg) }
 10% { transform: rotate(14.0deg) }
 20% { transform: rotate(-8.0deg) }
 30% { transform: rotate(14.0deg) }
 40% { transform: rotate(-4.0deg) }
 50% { transform: rotate(10.0deg) }
 60% { transform: rotate( 0.0deg) }  
100% { transform: rotate( 0.0deg) }
}

@keyframes bounce-animation {
  0%  { transform: translateY(-5px) }
  10% { transform: translateY(-14px) } 
  15% { transform: translateY(0px) }
  20% { transform: translateY(-6px) }
  25% { transform: translateY(0px) }
  30% { transform: translateY(-4px) }
  35% { transform: translateY(0px) }
  40% { transform: translateY(-2px) } 
  45% { transform: translateY(0px) } 
  50% { transform: translateY(-1px) } 
  60% { transform: translateY(0px) } 
 100% { transform: translateY(0px) }
}
@keyframes slide-in {
  from {
    transform: translateX(-5%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

@layer base {
   #name_section{
    font-family: "Editor";
    font-style: normal;
    font-weight: lighter;
  }

  #canvas3d {
    opacity: 0.4;
  }

  #logo img:hover{
    animation-name: wave-animation;  
    animation-duration: 2.5s;        
    animation-iteration-count: 1; 
    transform-origin: 50% 50%; 
    user-select: none;
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none;
    -o-user-select: none;
    -webkit-touch-callout: none;
  }

  .next_info_card_caption {
    animation-name: bounce-animation;  
    animation-duration: 5s;        
    animation-iteration-count: infinite; 
    transform-origin: 50% 50%; 
  }

  .info_card_title, .experience_year {
    writing-mode: vertical-rl;
    text-orientation: mixed;
  }

  .vertical-line {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    border-left: 1px solid var(--stone-color);
    position: relative;
    height: 80px;
    left: 50%;
  }

  .ease-out {
    opacity: 0;
    animation: slide-in 1s cubic-bezier(.075,.82,.165,1) forwards;
    animation-delay: 0.2s;
  }

  .ease-out:nth-child(1) {
    animation-delay: 0.25s;
  }
  
  .ease-out:nth-child(2) {
    animation-delay: 0.3s;
  }
  
  .ease-out:nth-child(3) {
    animation-delay: 0.35s;
  }

  .tech_section_background {
    background-image:    url(../public/tech_sec_bg.png);
    background-size:     cover;
    background-repeat:   no-repeat;
    background-position: center 20%;
  }

  .tech_item {
    font-family: "PPS";
  }

  .hide_scrollbar {
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none;  /* IE 10+ */
  }::-webkit-scrollbar { /* WebKit */
    width: 0px;
  }


.banner-wrap {
    position:relative;
}
.banner::after { 
    content: "";
    display:block;
    background: linear-gradient(0deg, black, var(--stone-color));
    width:200%;
    height:200px;
    position:absolute;
    left:-30%;
    top:0%;
    bottom: 50%;
    z-index:0;
    overflow-y: hidden;
    overflow: hidden;
}
.banner span {
  position: relative;
  padding-top: 3.5rem;
  z-index: 1;
}
  
}